import React from 'react'
import SbEditable from "storyblok-react"
import Button from './Button'
import Heading from './Heading'
import RichText from './RichText'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'

const TwoColumnContentAndImage = ({ blok }) => {
    const flip = blok.flip ? "lg:flex-row-reverse" : "lg:flex-row"
    const colour =
    blok.colour === 'blue'
    ? 'bg-giggles-blue'
    : blok.colour === 'red'
    ? 'bg-giggles-red'
    : blok.colour === 'yellow'
    ? 'bg-giggles-yellow'
    : blok.colour === 'green'
    ? 'bg-giggles-green'
    : blok.colour === 'white'
    ? 'bg-giggles-white'
    : ''

    const sources = blok.image.filename ?  [
        getFluidGatsbyImage(blok.image.filename, {maxWidth: 640, quality: 60}),
        {
            ...getFluidGatsbyImage(blok.image.filename, {maxWidth: 520, quality: 60}),
            media: `(max-width: 1536px)`,
        },
        {
            ...getFluidGatsbyImage(blok.image.filename, {maxWidth: 410, quality: 60}),
            media: `(max-width: 1280px)`,
        },
        {
            ...getFluidGatsbyImage(blok.image.filename, {maxWidth: 720, quality: 60}),
             media: `(max-width: 1024px)`,
        },
        {
            ...getFluidGatsbyImage(blok.image.filename, {maxWidth: 600, quality: 60}),
            media: `(max-width: 768px)`,
          },
      ] : ''
    

    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className="block md:my-24 my-12">
                <div className="container">
                    <div className={`flex ${flip} flex-col justify-between items-center`}>
                        <div className="lg:w-5/12 w-full">
                            <Heading className={`rfs:text-6xl lg:max-w-2xl max-w-lg`} colour={blok.colour}>{blok.heading}</Heading>
                            <RichText className="mt-8">{blok.content}</RichText>
                            {blok.button_link.cached_url && blok.button_text &&
                                <Button to={blok.button_link} className={`btn mt-8`} colour={blok.colour}>{blok.button_text}</Button>
                            }
                        </div>
                        <div className="lg:w-5/12 w-full lg:order-none order-first lg:mb-0 mb-16">
                            {blok.image &&
                            <div className="block relative">
                                {!blok.image.filename?.includes(".mp4") && blok.image.filename && 
                                    <Img fluid={sources} placeholderStyle={{ filter: `blur(0.5rem)` }} className="z-10 w-full h-full object-cover rounded-lg" alt={blok.image.alt}/>
                                }
                                {blok.image.filename?.includes(".mp4") && blok.image.filename && 
                                <div className="relative z-10">
                                    <video width="1920" height="1080" muted controlsList="nodownload" autoPlay loop playsInline className="z-10 block w-full h-full object-cover rounded-lg">
                                        <source src={blok.image ? blok.image.filename : ''} type="video/mp4"/>
                                        Sorry, your browser doesn't support embedded videos.
                                    </video>
                                </div>
                                }
                                <div className={`absolute block ${colour} w-full h-full md:top-6 top-4 md:left-6 left-4 rounded-lg`}></div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </SbEditable>
    )
}

export default TwoColumnContentAndImage