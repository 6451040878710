import React from 'react'
import SbEditable from "storyblok-react"

const Spacer = ({ blok }) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`${blok.size === 'big' ? 'h-64' : blok.size === 'small' ? 'h-16' : 'h-32'}`}></div>
        </SbEditable>
    )
}

export default Spacer