import React from 'react'
import SbEditable from "storyblok-react"
import Button from './Button'
import Heading from './Heading'
import FullWidthCtaChar1 from "/static/images/full_width_cta_char_1.svg"
import FullWidthCtaChar2 from "/static/images/full_width_cta_char_2.svg"

const FullWidthContent = ({ blok }) => {
    const colour =
    blok.background_colour === 'blue'
    ? 'bg-giggles-blue'
    : blok.background_colour === 'red'
    ? 'bg-giggles-red'
    : blok.background_colour === 'yellow'
    ? 'bg-giggles-yellow'
    : blok.background_colour === 'green'
    ? 'bg-giggles-green'
    : blok.background_colour === 'white'
    ? 'bg-white'
    : ''

    const subheadingColour =
    blok.background_colour === 'blue'
    ? 'text-giggles-blue-hover'
    : blok.background_colour === 'red'
    ? 'text-giggles-red-hover'
    : blok.background_colour === 'yellow'
    ? 'text-giggles-yellow-hover'
    : blok.background_colour === 'green'
    ? 'text-giggles-green-hover'
    : blok.background_colour === 'white'
    ? 'text-white'
    : ''

    return (
        <SbEditable content={blok} key={blok._uid}>
            <div>
                {blok.background_colour !== 'green' &&
                    <FullWidthCtaChar1 className="-mb-28 mx-auto"/>
                }
                {blok.background_colour === 'green' &&
                    <FullWidthCtaChar2 className="-mb-28 mx-auto"/>
                }
                <div className={`${colour} relative`}>
                    <div className="container md:py-24 py-12 flex lg:flex-row flex-col flex-nowrap justify-between lg:items-center">
                        <div>
                            <Heading className={`rfs:text-6xl mx-auto block md:mb-0 mb-2`} colour={'white'}>{blok.heading}</Heading>
                            <Heading className={`rfs:text-4xl mx-auto block ${subheadingColour} `}>{blok.subheading}</Heading>
                        </div>
                        {blok.button_link.cached_url && blok.button_text &&
                            <div>
                                <Button to={blok.button_link} className={`btn lg:mt-0 mt-6`} colour={blok.background_colour+'-hover'}>{blok.button_text}</Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </SbEditable>
    )
}

export default FullWidthContent