import React, { useState } from "react";
import SbEditable from "storyblok-react";
import ContactFormChar from "/static/images/contact_form_char.svg";
import { useStaticQuery, graphql } from "gatsby";
import RichText from "./RichText";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

const FullWidthContent = ({ blok }) => {
  // Get Storyblok settings
  const { settings } = useStaticQuery(graphql`
    query SettingsContactForm {
      settings: allStoryblokEntry(
        filter: { field_component: { eq: "settings" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
    }
  `);
  let content = JSON.parse(settings.edges[0].node.content);
  let parsedSetting = Object.assign({}, content, { content: content });

  // Below are all map settings

  const MapStyles = require("../../static/general/MapStyles.json");

  const containerStyle = {
    width: "100%",
    height: "570px",
  };

  const center = {
    lat: 53.81031054928035,
    lng: -1.4589431874565655,
  };

  const centers = [
    {
      lat: 53.80567210410469,
      lng: -1.452768747650313,
      // Station Road
      // 53.80567210410469, -1.452768747650313
    },
    {
      lat: 53.81511206264646,
      lng: -1.4505421988306868,
      // Barwick Road
      // 53.81511206264646, -1.4505421988306868
    },
  ];

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAx_zmKfY_vKzX_tzpft_90S3YRn84HxlU",
    preventGoogleFontsLoading: true,
  });

  /* eslint-disable no-unused-vars */
  const [map, setMap] = React.useState(null);
  /* eslint-enable no-unused-vars */

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const divStyle = {
    background: `white`,
    border: `1px solid #ccc`,
    padding: "1.5rem",
  };

  const [infoWindow, setInfoWindow] = useState(null);

  function handleClick(location) {
    setInfoWindow(location);
  }

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="mb-20 md:mb-40">
        {isLoaded && !blok.hide_map && (
          <GoogleMap
            options={{
              styles: MapStyles,
              mapTypeControl: false,
              streetViewControl: false,
              fullscreenControl: false,
            }}
            mapContainerStyle={containerStyle}
            center={center}
            zoom={13}
            onLoad={onLoad}
            onUnmount={onUnmount}
            clickableIcons={true}
          >
            {/* Child components, such as markers, info windows, etc. */}
            <Marker
              icon={
                "https://a.storyblok.com/f/161275/x/a9dcd3ba77/rascals-map-marker.svg"
              }
              position={centers[0]}
              clickable={true}
              onClick={() => {
                handleClick("Station Road");
              }}
            />
            {infoWindow === "Station Road" && (
              <InfoWindow
                onLoad={onLoad}
                position={centers[0]}
                onCloseClick={() => {
                  handleClick(null);
                }}
              >
                <div style={divStyle}>
                  <h3 class="text-giggles-blue rfs:text-5xl my-4">Station Road</h3>
                  <p class="mb-6">
                    Station Road <br />
                    LS15 7JY
                  </p>
                  <a href="tel:0113 249 6760" className="block text-[#646363]">
                    <span class="text-giggles-blue">T</span> 0113 2496760
                  </a>
                  <a
                    href="mailto:info@gigglesdaynursery.org"
                    class="block whitespace-nowrap text-[#646363]"
                  >
                    <span class="text-giggles-blue">E</span>{" "}
                    info@gigglesdaynursery.org
                  </a>
                </div>
              </InfoWindow>
            )}
            <Marker
              icon={
                "https://a.storyblok.com/f/161275/x/a9dcd3ba77/rascals-map-marker.svg"
              }
              position={centers[1]}
              clickable={true}
              onClick={() => {
                handleClick("Barwick Road");
              }}
            />
            {infoWindow === "Barwick Road" && (
              <InfoWindow
                onLoad={onLoad}
                position={centers[1]}
                onCloseClick={() => {
                  handleClick(null);
                }}
              >
                <div style={divStyle}>
                  <h3 class="text-giggles-yellow rfs:text-5xl my-4">Barwick Road</h3>
                  <p class="mb-6">
                    Barwick Road <br />
                    LS15 8RQ
                  </p>
                  <a href="tel:0113 3008147" className="block text-[#646363]">
                    <span class="text-giggles-yellow">T</span> 0113 3008147
                  </a>
                  <a
                    href="mailto:bramley@gigglesdaynursery.org"
                    class="block whitespace-nowrap text-[#646363]"
                  >
                    <span class="text-giggles-yellow">E</span>{" "}
                    bramley@gigglesdaynursery.org
                  </a>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        )}
        {!blok.hide_form && (
          <div className="bg-[#FBFBFB]">
            <div className="flex flex-col lg:flex-row">
              <div className="bg-giggles-green relative flex w-full flex-col p-8 text-white lg:w-4/12 lg:items-center lg:justify-center lg:p-16">
                <div className="lg:mb-40">
                  <h2 className="rfs:text-6xl mb-6">Contact Us</h2>
                  <p className="max-w-md">
                    If you have a general enquiry about any of our Little Rascals Nurseries please use the enquiry form. If you wish to contact one of our two nursery locations with a question or to enrol your child please use the links below.
                  </p>
                  <div className="mt-6 flex flex-row flex-wrap items-start gap-2">
                    <a
                      href="https://app.famly.co/#/inquiry?institutionId=1ac8cb72-436d-42ed-a388-cc13948bf2f7&hmac=7e403f9b8a840bfe0df9cd8de5828fdd02d5af470ef80d993346ed6566db6c90&locale=en_GB"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-green-hover btn-small"
                    >
                      Station Road
                    </a>
                    <a
                      href="https://app.famly.co/#/inquiry?institutionId=74b61d13-3b61-4049-98a5-031c17fbb328&hmac=e8bb615ec778e42ee0c4d1300646c9839a48dd27fca081e82e6904144cc01be7&locale=en_GB"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-green-hover btn-small"
                    >
                      Barwick Road
                    </a>
                  </div>
                  <div className="my-6 h-px w-8 bg-white"></div>
                  <p className="font-bold">Opening Times</p>
                  <RichText>{parsedSetting.opening_times}</RichText>
                  <div className="mt-6 flex text-xl lg:items-start lg:justify-start">
                    <a
                      href="https://www.facebook.com/profile.php?id=100063487392926"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-giggles-green-hover p-2 pl-0 text-white transition-all duration-300 ease-in-out"
                    >
                      <i className="fab fa-facebook"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/littlerascals_leeds/"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-giggles-green-hover p-2 text-white transition-all duration-300 ease-in-out"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
                <ContactFormChar className="absolute bottom-0 right-12 hidden sm:block" />
              </div>
              <div className="w-full p-8 text-[#646363] lg:w-8/12 lg:p-16">
                <h2 className="rfs:text-4xl">Send us a message</h2>
                <form
                  netlify
                  method="POST"
                  action="/contact-us/thank-you"
                  name="contact"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                >
                  <div className="mt-6 grid grid-cols-1 gap-x-12 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label htmlFor="first_name" className="label-default">
                        First name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="first_name"
                          id="first_name"
                          autoComplete="given-name"
                          required
                          placeholder="First name"
                          className="input-default"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label htmlFor="last_name" className="label-default">
                        Last name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="last_name"
                          id="last_name"
                          autoComplete="family-name"
                          required
                          placeholder="Last name"
                          className="input-default"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label htmlFor="email" className="label-default">
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          autoComplete="email"
                          required
                          placeholder="Email address"
                          className="input-default"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <div className="flex flex-row justify-between">
                        <label htmlFor="phone" className="label-default">
                          Phone
                        </label>
                        <span className="text-base-new text-[#6B7280]">
                          Optional
                        </span>
                      </div>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          autoComplete="tel"
                          placeholder="Phone"
                          className="input-default"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-6">
                      <label htmlFor="subject" className="label-default">
                        Subject
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          required
                          placeholder="Subject"
                          className="input-default"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-6">
                      <div className="flex flex-row justify-between">
                        <label htmlFor="message" className="label-default">
                          Message
                        </label>
                        <span className="text-base-new text-[#6B7280]">
                          Max. 500 characters
                        </span>
                      </div>
                      <div className="mt-1">
                        <textarea
                          type="text"
                          name="message"
                          id="message"
                          required
                          placeholder="Message"
                          rows={5}
                          className="input-default"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <button type="submit" className="btn btn-green mt-6">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </SbEditable>
  );
};

export default FullWidthContent;
