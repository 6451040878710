import React from "react";
import Link from "gatsby-link";
import resolveLink from "../utils/resolveLink";

const Button = ({ to, className, colour, children, isAsset, target }) => {
  let classes = className + " ";
  classes +=
    colour === "blue"
      ? "btn-blue"
      : colour === "blue-hover"
      ? "btn-blue-hover"
      : colour === "red"
      ? "btn-red"
      : colour === "red-hover"
      ? "btn-red-hover"
      : colour === "yellow"
      ? "btn-yellow"
      : colour === "yellow-hover"
      ? "btn-yellow-hover"
      : colour === "green"
      ? "btn-green"
      : colour === "green-hover"
      ? "btn-green-hover"
      : colour === "white"
      ? "btn-white"
      : "";

  if (to.linktype === "url" || to.linktype === "asset") {
    return (
      <a
        href={to.cached_url}
        target="_blank"
        rel="noreferrer"
        className={classes}
      >
        {children}
      </a>
    );
  } else {
    return (
      <Link to={resolveLink(`/${to.cached_url}`)} className={classes}>
        {children}
      </Link>
    );
  }
};

export default Button;
