import React, { useRef, useState } from 'react'
import SbEditable from "storyblok-react"
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'

const FullWidthVideo = ({ blok }) => {
    const colour =
    blok.colour === 'blue'
    ? 'bg-giggles-blue'
    : blok.colour === 'red'
    ? 'bg-giggles-red'
    : blok.colour === 'yellow'
    ? 'bg-giggles-yellow'
    : blok.colour === 'green'
    ? 'bg-giggles-green'
    : blok.colour === 'white'
    ? 'bg-white'
    : ''

    const vidRef = useRef(false);
    const [hide, setHide] = useState(false);
    const handlePlayVideo = () => {
        vidRef.current.play()
        setHide(true)
    }

    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className="md:my-40 my-20">
                <div className="container">
                    <div className="relative">
                        <div className="relative z-10">
                            <video controls width="1920" height="1080" muted controlsList="nodownload" ref={vidRef} className="z-10 block w-full h-full object-cover rounded-lg shadow-lg">
                                <source src={blok.video.filename} type="video/mp4"/>
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                        </div>
                        <div onClick={handlePlayVideo} onKeyDown={handlePlayVideo} role="button" tabIndex="0" className={`absolute block z-20 top-0 left-0 w-full h-full object-cover rounded-lg cursor-pointer transition-all duration-500 ease-in-out ${hide ? 'opacity-0 invisible' : 'opacity-100 visible'}`}>
                            <Img fluid={getFluidGatsbyImage(blok.thumbnail.filename, {maxWidth: 1600, quality: 60})} placeholderStyle={{ filter: `blur(0.5rem)` }} className={`z-10 block w-full h-full object-cover rounded-lg shadow-lg`} alt={blok.thumbnail.filename}/>
                            <div className={`absolute z-20 top-1/2 left-1/2 h-24 w-24 rounded-full ${colour}`} style={{top: 'calc(50% - 48px)',left: 'calc(50% - 48px)'}}>
                                <div className="play-button"></div>
                            </div>
                        </div>
                        <div className={`absolute block z-1 ${colour} w-full h-full md:top-6 top-4 md:left-6 left-4 rounded-lg shadow-lg`}></div>
                    </div>
                </div>
            </div>
        </SbEditable>
    )
}

export default FullWidthVideo