import React from 'react'
import SbEditable from 'storyblok-react'
import FamlyApp from "/static/images/famly_app.svg"
import { osName } from "react-device-detect";
import Img from "gatsby-image";
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'
import RichText from './RichText'

const DownloadTheFamlyApp = ({ blok }) => {
    return (
        <SbEditable content={blok}>
            { blok.download_app ? 
            <div className="lg:py-24 py-12">
                <div className="container">
                    <div className="flex lg:flex-row flex-col justify-center items-center">
                        <div className="bg-[#191222] sm:h-96 sm:w-96 h-64 w-64 flex justify-center items-center rounded-lg lg:-mr-60 lg:-mb-0 sm:-mb-12 -mb-6 z-10">
                            <FamlyApp className="w-8/12"/>
                        </div>
                        <div className="bg-giggles-blue rounded-lg text-white xl:py-40 sm:py-24 py-12 lg:px-32 sm:px-24 px-12 lg:w-9/12 w-full">
                            <div className="lg:pl-48">
                                <h2 className="rfs:text-6xl">Download the Famly app</h2>
                                <p className="my-6">Famly is an easy-to-use childcare management platform designed for childcare centers, daycares, preschools and nurseries. Available on <a href="https://apps.apple.com/gb/app/famly-app/id807454588" target="_blank" rel="noreferrer" className="underline">Apple App Store</a> or <a href="https://play.google.com/store/apps/details?id=com.famly.famly&hl=en_GB&gl=US" target="_blank" rel="noreferrer" className="underline">Google Play</a>.</p>
                                <a href={osName === "Android" ? 'https://play.google.com/store/apps/details?id=com.famly.famly&hl=en_GB&gl=US' : 'https://apps.apple.com/gb/app/famly-app/id807454588'} target="_blank" rel="noreferrer" className="btn btn-blue-hover">Download now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
            :
            <div className="lg:py-24 py-12">
                <div className="container">
                    <div className="flex lg:flex-row flex-col justify-center items-center">
                        <div className="bg-[#191222] sm:h-96 sm:w-96 h-64 w-64 flex justify-center items-center rounded-lg lg:-mr-60 lg:-mb-0 sm:-mb-12 -mb-6 z-10">
                            <Img placeholderStyle={{ filter: `blur(1rem)` }}
                                 fluid={blok.image?.filename ? [
                                    getFluidGatsbyImage(blok.image?.filename, {maxWidth: 600, quality: 100})
                                ] : ''} 
                                className="w-full h-full object-cover rounded-lg" alt={blok.image?.alt}/>                            
                        </div>
                        <div className="bg-giggles-blue rounded-lg text-white xl:py-40 sm:py-24 py-12 lg:px-32 sm:px-24 px-12 lg:w-9/12 w-full">
                            <div className="lg:pl-48">
                                <h2 className="rfs:text-6xl">{blok.title}</h2>
                                <p className="my-6">
                                    <RichText>{blok.content}</RichText>
                                </p>
                                {blok.button_label &&
                                    <a href={blok.button_target.cached_url} className="btn btn-blue-hover">{blok.button_label}</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
            }
        </SbEditable>
    )
}

export default DownloadTheFamlyApp