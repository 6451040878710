import React from 'react'
import SbEditable from "storyblok-react"
import Button from './Button'
import Heading from './Heading'
import RichText from './RichText'
import FullWidthCharLeft1 from "/static/images/full_width_char_left_1.svg"
import FullWidthCharRight1 from "/static/images/full_width_char_right_1.svg"
import FullWidthCharLeft2 from "/static/images/slider_left_char.svg"
import FullWidthCharRight2 from "/static/images/slider_right_char.svg"

const FullWidthContent = ({ blok }) => {
    
    let colour =
    blok.background_colour === 'blue'
    ? 'bg-giggles-blue'
    : blok.background_colour === 'red'
    ? 'bg-giggles-red'
    : blok.background_colour === 'yellow'
    ? 'bg-giggles-yellow'
    : blok.background_colour === 'green'
    ? 'bg-giggles-green'
    : blok.background_colour === 'white'
    ? 'bg-white'
    : ''

    colour = blok.inverse_colours ? 'bg-white' : colour

    const leftChar = blok.background_colour === 'red' ?  <FullWidthCharLeft2 className="md:block hidden absolute left-0" style={{top: 'calc(50% - 170px)'}}/> :  <FullWidthCharLeft1 className="md:block hidden absolute left-0" style={{top: 'calc(50% - 170px)'}}/>
    const rightChar = blok.background_colour === 'blue' ?  <FullWidthCharRight2 className="md:block hidden absolute right-0" style={{top: 'calc(50% - 183px)'}}/> :  <FullWidthCharRight1 className="md:block hidden absolute right-0" style={{top: 'calc(50% - 183px)'}}/>
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`${colour} relative ${blok.negative_margin ? '-mt-24 pt-24' : ''}`}>
                <div className="container text-center md:py-24 py-12">
                    <Heading className={`rfs:text-6xl lg:max-w-2xl max-w-lg mx-auto block`} colour={blok.inverse_colours ? blok.background_colour : 'white'}>{blok.heading}</Heading>
                    <div className={`mt-6 lg:max-w-2xl max-w-xl mx-auto ${blok.inverse_colours ? '' : 'text-white'}`}>
                        <RichText>{blok.content}</RichText>
                    </div>
                    {blok.button_link.cached_url && blok.button_text &&
                        <Button to={blok.button_link} className={`btn mt-6`} colour={blok.background_colour+'-hover'}>{blok.button_text}</Button>
                    }
                </div>
                {!blok.hide_characters && 
                    <div>
                        {leftChar}
                        {rightChar}
                    </div>
                }
            </div>
        </SbEditable>
    )
}

export default FullWidthContent