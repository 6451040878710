import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import SbEditable from "storyblok-react"
import RichText from './RichText'

const NurseryRoutine = ({ blok }) => {
    // Get Storyblok settings
    const { settings } = useStaticQuery(graphql`
    query SettingsNurseryRoutine {
      settings: allStoryblokEntry(filter: {field_component: {eq: "settings"}}) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
    }
    `)
    let content = JSON.parse(settings.edges[0].node.content)
    let parsedSetting = Object.assign({}, content, {content: content})

    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className="bg-giggles-yellow md:py-24 py-12">
                <div className="container text-center">
                    <h2 className="rfs:text-6xl mb-6 text-white">Nursery routine</h2>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:grid-rows-3 md:grid-rows-2 grid-rows-1 gap-x-8 gap-y-12 w-full mt-16">
                        {parsedSetting.slots.map((slot, index)=>(
                            <div className="w-full flex h-full relative" key={index}>
                                <div 
                                    className={`rounded-lg shadow-lg h-12 w-12 absolute -top-6 flex justify-center items-center
                                    ${slot.colour === 'blue' ? 'bg-giggles-blue' : slot.colour === 'red' ? 'bg-giggles-red' : slot.colour === 'green' ? 'bg-giggles-green' : slot.colour === 'yellow' ? 'bg-giggles-yellow' : ''}`} 
                                    style={{left: 'calc(50% - 24px)'}}>
                                    <img src={`/images/${slot.time.split('.').join("").split(' ').join("")}.svg`} alt="" />
                                </div>
                                <div className="bg-white rounded-lg text-center px-8 pb-12 pt-16 w-full">
                                    <tt className="text-[#3F3F3F] font-bold">{slot.time}</tt>
                                    <p className="text-[#3F3F3F] font-body">{slot.title}</p>
                                    <RichText className="mt-4">{slot.description}</RichText>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </SbEditable>
    )
}

export default NurseryRoutine
