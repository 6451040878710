import React from 'react'
import Placeholder from './Placeholder'
import Hero from './Hero'
import TwoColumnContent from './TwoColumnContent'
import FullWidthContent from './FullWidthContent'
import FullWidthCta from './FullWidthCta'
import ImageRow from './ImageRow'
import TwoColumnContentAndImage from './TwoColumnContentAndImage'
import NurseryLocationsAndOpeningTimes from './NurseryLocationsAndOpeningTimes'
import CenteredBlockWithImage from './CenteredBlockWithImage'
import TestimonialsSlider from './TestimonialsSlider'
import ContactUsFormWithMap from './ContactUsFormWithMap'
import NurseryRoutine from './NurseryRoutine'
import TwoColumnImages from './TwoColumnImages'
import SliderBlock from './SliderBlock'
import FullWidthVideo from './FullWidthVideo'
import FAQs from './FAQs'
import Spacer from './Spacer'

const Components = {
  'hero': Hero,
  'two_column_content': TwoColumnContent,
  'full_width_content': FullWidthContent,
  'full_width_cta': FullWidthCta,
  'image_row': ImageRow,
  'two_column_content_and_image': TwoColumnContentAndImage,
  'nursery_locations_and_opening_times': NurseryLocationsAndOpeningTimes,
  'centered_block_with_image': CenteredBlockWithImage,
  'testimonials_slider': TestimonialsSlider,
  'contact_us_form_with_map': ContactUsFormWithMap,
  'nursery_routine': NurseryRoutine,
  'two_column_images': TwoColumnImages,
  'slider': SliderBlock,
  'full_width_video': FullWidthVideo,
  'faqs': FAQs,
  'spacer': Spacer,
}

const DynamicComponent = ({blok}) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return <Component blok={blok} key={blok._uid}/>
  }
 
  return  blok.component ? <Placeholder componentName={blok.component}/> : null
}

export default DynamicComponent
