import React from 'react'
import SbEditable from "storyblok-react"
import { useStaticQuery, graphql } from "gatsby"
import NurseryLocationsChar from "/static/images/nursery_locations_char.svg"
import RichText from './RichText'

const FullWidthContent = ({ blok }) => {
    const { settings } = useStaticQuery(graphql`
    query SettingsNurseryLocations {
      settings: allStoryblokEntry(filter: {field_component: {eq: "settings"}}) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
    }
    `)
    let content = JSON.parse(settings.edges[0].node.content)
    let parsedSetting = Object.assign({}, content, {content: content})

    return (
        <SbEditable content={blok} key={blok._uid}>
            {!blok.hide_top &&
            <div className="bg-[#FBFBFB]">
                <div className="lg:max-w-6xl max-w-3xl mx-auto xl:px-0 px-6 relative md:py-24 py-12">
                    <h2 className="rfs:text-6xl max-w-lg text-giggles-red mb-8 ">Our nursery locations and opening times</h2>
                    <div className="flex lg:flex-row flex-col justify-between items-start">
                        <div className="lg:w-5/12 w-full">
                            <p className="max-w-lg">We have two Little Rascals Nurseries located within the Crossgates area of Leeds. These are on Station Road and Barwick Road. We're open 52 weeks of the year, excluding bank holidays.</p>
                            <p className="text-giggles-red font-bold mt-6">Opening times</p>
                            <RichText>{parsedSetting.opening_times}</RichText>
                        </div>
                        <div className="lg:w-5/12 w-full lg:mt-0 mt-6 grid grid-rows-3 grid-cols-2 gap-4">
                            {parsedSetting.sessions.map((session, index)=>(
                                <div key={index}>
                                    <p className="text-giggles-red font-bold">{session.title}</p>
                                    <p>{session.time}</p>
                                </div>  
                            ))}
                        </div>
                    </div>
                    <NurseryLocationsChar className="absolute top-0 right-0 md:block hidden"/>
                </div>
            </div>
            }
            {!blok.hide_bottom &&
            <div className={`container md:py-24 py-12 ${!blok.hide_top ? 'lg:-mt-32' : ''}`}>
                <div className="flex lg:flex-row flex-col flex-nowrap justify-center items-center lg:space-y-0 space-y-16 text-[#646363]">
                    <div className="lg:w-1/3 flex flex-col justify-center items-center text-center">
                        <div className="h-12 w-12 bg-giggles-blue rounded-lg flex justify-center items-center text-white text-xl">
                            <i className="far fa-map-marker-alt"></i>
                        </div>
                        <h3 className="text-giggles-blue rfs:text-5xl my-4">Station Road</h3>
                        <p className="mb-6">Wellgarth, Station Road <br/>Crossgates, Leeds LS15 7JY</p>
                        <a href="tel:0113 2326757"><span className="text-giggles-blue">T</span> 0113 2326757</a>
                        <a href="mailto:stationroad@littlerascalsnurseries.com" className="whitespace-nowrap"><span className="text-giggles-blue">E</span> stationroad@littlerascalsnurseries.com</a>
                        <a href="https://app.famly.co/#/inquiry?institutionId=1ac8cb72-436d-42ed-a388-cc13948bf2f7&hmac=7e403f9b8a840bfe0df9cd8de5828fdd02d5af470ef80d993346ed6566db6c90&locale=en_GB" target="_blank" rel="noreferrer" className="btn btn-blue btn-small mt-6">Enquire Now</a>
                    </div>
                    <div className="lg:w-1/3 flex flex-col justify-center items-center text-center">
                        <div className="h-12 w-12 bg-giggles-yellow rounded-lg flex justify-center items-center text-white text-xl">
                            <i className="far fa-map-marker-alt"></i>
                        </div>
                        <h3 className="text-giggles-yellow rfs:text-5xl my-4">Barwick Road</h3>
                        <p className="mb-6">Barwick Road, Crossgates, <br/>Leeds LS15 8RQ</p>
                        <a href="tel:0113 2326858"><span className="text-giggles-yellow">T</span> 0113 2326858</a>
                        <a href="mailto:barwickroad@littlerascalsnurseries.com" className="whitespace-nowrap"><span className="text-giggles-yellow">E</span> barwickroad@littlerascalsnurseries.com</a>
                        <a href="https://app.famly.co/#/inquiry?institutionId=74b61d13-3b61-4049-98a5-031c17fbb328&hmac=e8bb615ec778e42ee0c4d1300646c9839a48dd27fca081e82e6904144cc01be7&locale=en_GB" target="_blank" rel="noreferrer" className="btn btn-yellow btn-small mt-6">Enquire Now</a>
                    </div>
                </div>
            </div>
            }
        </SbEditable>
    )
}

export default FullWidthContent