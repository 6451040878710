import React from 'react'
import SbEditable from "storyblok-react"
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'

const TwoColumnImages = ({ blok }) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className="block md:my-24 my-12">
                <div className="container">
                    <div className="flex lg:flex-row flex-col flex-wrap justify-start items-start two-column-images-grid">
                        {blok.images.map((image, index)=>(
                            <div className="lg:w-1/2 w-full" key={index}>
                                <div className="block relative">
                                    {!image.filename?.includes(".mp4") && image.filename && 
                                    <Img fluid={getFluidGatsbyImage(image.filename, {maxWidth: 750, quality: 60})} placeholderStyle={{ filter: `blur(0.5rem)` }} className="z-10 w-full h-full object-cover rounded-lg" alt={image.alt}/>
                                    }
                                    {image.filename?.includes(".mp4") && image.filename && 
                                    <div className="relative z-10">
                                        <video width="1920" height="1080" muted controlsList="nodownload" autoPlay loop playsInline className="z-10 block w-full h-full object-cover rounded-lg">
                                            <source src={image.filename} type="video/mp4"/>
                                            Sorry, your browser doesn't support embedded videos.
                                        </video>
                                    </div>
                                    }
                                    <div className={`absolute block w-full h-full top-4 left-4 rounded-lg`}></div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </SbEditable>
    )
}

export default TwoColumnImages