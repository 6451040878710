import React, { useState, useRef, useEffect} from 'react'
import SbEditable from "storyblok-react"
import RichText from './RichText'
import Heading from './Heading'
import SliderLeftChar from "/static/images/slider_left_char.svg"
import SliderRightChar from "/static/images/slider_right_char.svg"
import SliderLeftChar2 from "/static/images/full_width_char_left_1.svg"
import SliderRightChar2 from "/static/images/full_width_char_right_1.svg"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'

const SliderBlock = ({ blok }) => {
    let colour =
    blok.colour === 'blue'
    ? 'bg-giggles-blue'
    : blok.colour === 'red'
    ? 'bg-giggles-red'
    : blok.colour === 'yellow'
    ? 'bg-giggles-yellow'
    : blok.colour === 'green'
    ? 'bg-giggles-green'
    : blok.colour === 'white'
    ? 'bg-white'
    : ''

    const [nav1, setNav1] = useState(null)
    const [nav2, setNav2] = useState(null)

    const slider1 = useRef(null)
    const slider2 = useRef(null)
  
    useEffect(() => {
      setNav1(slider1.current)
      setNav2(slider2.current)
    }, [slider1, slider2])

    const settingsBottom = {
        infinite: true,
        arrows: false,
        fade: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: true,
        dotsClass: `slick-dots ${blok.colour}`,
    };

    const settingsTop = {
        className: "center scale mb-20",
        centerMode: true,
        infinite: true,
        centerPadding: "25%",
        slidesToShow: 1,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 10000,
        arrows: false,
        dots: false,
        swipeToSlide: true,
        focusOnSelect: true,
        onSwipe: () => {
          slider1.current.slickPause()
          // console.log('stop')
          setTimeout(() => {
            slider1.current.slickPlay()
            // console.log('play')
          }, 20000)
        },
        responsive: [
          {
            breakpoint: 768,
            settings: {
              centerPadding: "10%",
            },
          },
        ],
    };

    const leftChar = blok.colour === 'yellow' ?  <SliderLeftChar2 className="md:block hidden absolute left-0 bottom-0"/> :  <SliderLeftChar className="md:block hidden absolute left-0 bottom-0"/>
    const rightChar = blok.colour === 'green' ?  <SliderRightChar2 className="md:block hidden absolute right-0 bottom-0"/> :  <SliderRightChar className="md:block hidden absolute right-0 bottom-0"/>
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`relative ${blok.remove_top_margin ? 'md:mb-40 mb-20' : 'md:my-40 my-20'}`}>
                <div className={`${colour} text-center md:pt-36 pt-16 md:pb-36 pb-28 relative`}>
                    <div className="container">
                        <h2 className="rfs:text-6xl text-white md:mb-10 mb-6">{blok.title}</h2>
                        <RichText className="text-white max-w-2xl mx-auto">{blok.description}</RichText>
                    </div>
                    {leftChar}
                    {rightChar}
                </div>
                <Slider {...settingsTop} asNavFor={nav2} ref={slider1} className="-mt-16">
                    {blok.slides.map((slide, index) => (
                        <div className="px-3 rounded-lg" key={index}>
                            {slide.image.filename && <Img fluid={getFluidGatsbyImage(slide.image.filename, {maxWidth: 1300, quality: 60})} placeholderStyle={{ filter: `blur(0.5rem)` }} className="z-10 w-full h-full object-cover rounded-lg" alt={slide.image.filename}/>}
                        </div>
                    ))}
                </Slider>
                <div className="container text-center">
                    <Slider {...settingsBottom} asNavFor={nav1} ref={slider2} className="mx-auto max-w-3xl mt-12">
                        {blok.slides.map((slide, index) => (
                            <div className="max-w-xl mx-auto" key={index}>
                                <Heading className={`rfs:text-6xl mb-6`} colour={blok.colour}>{slide.title}</Heading>
                                <RichText>{slide.content}</RichText>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </SbEditable>
    )
}

export default SliderBlock