import React from 'react'
import SbEditable from "storyblok-react"
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'

const FullWidthContent = ({ blok }) => {

    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className="relative z-10">
                <div className="container md:pt-24 pt-12">
                    <div className="flex flex-row flex-wrap justify-center items-start image-row">
                        {blok.images.map((image, index) => (
                            <div className="md:w-1/4 w-1/2 lg:p-2 p-1" key={index}>
                                {!image.filename?.includes(".mp4") && image.filename &&
                                <Img placeholderStyle={{ filter: `blur(1rem)` }}
                                 fluid={image.filename ? [
                                    getFluidGatsbyImage(image.filename, {maxWidth: 370, quality: 60}),
                                    {
                                        ...getFluidGatsbyImage(image.filename, {maxWidth: 300, quality: 60}),
                                        media: `(max-width: 1536px)`,
                                    },
                                    {
                                        ...getFluidGatsbyImage(image.filename, {maxWidth: 230, quality: 60}),
                                        media: `(max-width: 1280px)`,
                                    },
                                    {
                                        ...getFluidGatsbyImage(image.filename, {maxWidth: 180, quality: 60}),
                                        media: `(max-width: 1024px)`,
                                    },
                                    {
                                        ...getFluidGatsbyImage(image.filename, {maxWidth: 140, quality: 60}),
                                        media: `(max-width: 768px)`,
                                    },
                                    {
                                        ...getFluidGatsbyImage(image.filename, {maxWidth: 88, quality: 60}),
                                        media: `(max-width: 425px)`,
                                    },
                                ] : ''} 
                                className="w-full h-full object-cover rounded-lg" alt={image.alt}/>
                                }
                                {image.filename?.includes(".mp4") && image.filename && 
                                <div className="relative z-10">
                                    <video width="1920" height="1080" muted controlsList="nodownload" autoPlay loop playsInline className="z-10 block w-full h-full object-cover rounded-lg">
                                        <source src={image.filename} type="video/mp4"/>
                                        Sorry, your browser doesn't support embedded videos.
                                    </video>
                                </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </SbEditable>
    )
}

export default FullWidthContent