import React, { useState, useRef, useLayoutEffect } from "react"
import SbEditable from "storyblok-react"
import RichText from './RichText'
import { useStaticQuery, graphql } from "gatsby"

const AccordionItem = props => {
    // Set ref to get the height of each accordion
    const [height, setHeight] = useState(0)
    const ref = useRef()
  
    useLayoutEffect(() => {
      const updateHeight = () => {
        if (!ref.current) return
        const { height } = ref.current.getBoundingClientRect()
        setHeight(height)
      }
  
      updateHeight()
  
      window.addEventListener("resize", updateHeight, {passive : true})
      return () => {
        window.removeEventListener("resize", updateHeight, {passive : true})
      }
    })
  
    // The click function
    const [opened, setOpen] = useState(false)
    const handleClick = () => {
      setOpen(!opened)
    }
  
      return (
          <div className="border-t border-gold group text-[#646363]">
            <div
              className="w-full relative py-6 cursor-pointer focus:outline-none flex justify-between items-center"
              role="button"
              tabIndex={0}
              onClick={handleClick}
              onKeyDown={handleClick}
            >
              <h2
                className={`font-body pr-12 group-hover:text-giggles-red duration-500 ${
                  opened ? "text-giggles-red" : ""
                }`}
              >
                {props.data.title}
              </h2>
              <i className={`fas fa-chevron-down block duration-500 group-hover:text-giggles-red transform origin-center ${opened ? 'rotate-180' : ''}`}></i>
            </div>
            <div
              className={`w-full lg:pr-20 overflow-hidden transition-all duration-500 ease-in-out`}
              style={{ maxHeight: opened ? height : 0 }}
            >
              <div className="pb-6 text-sm" ref={ref}>
                <RichText>{props.data.content}</RichText>
              </div>
            </div>
          </div>
      )
  }

const FullWidthContent = ({ blok }) => {
    const { settings } = useStaticQuery(graphql`
    query SettingsFAQs {
      settings: allStoryblokEntry(filter: {field_component: {eq: "settings"}}) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
    }
    `)
    let content = JSON.parse(settings.edges[0].node.content)
    let parsedSetting = Object.assign({}, content, {content: content})

    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`${blok.disable_grey_background ? "md:my-40 my-20" : 'bg-[#FBFBFB] md:py-40 py-20'}`}>
                <div className="container">
                    <h2 className="rfs:text-6xl text-[#646363] text-center mb-12">Frequently asked questions</h2>
                    <div className="max-w-3xl mx-auto">
                        {parsedSetting.faqs.map((item, index)=>(
                            <AccordionItem data={item} key={index} />
                        ))}
                    </div>
                </div>
            </div>
        </SbEditable>
    )
}

export default FullWidthContent