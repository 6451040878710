import React from 'react'
import SbEditable from "storyblok-react"
import Button from './Button'
import Heading from './Heading'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'
// Potential window width functionality from https://stackoverflow.com/a/65001396
// Didn't work very well so i'm doing it the shitty way

const Hero = ({ blok }) => {

    const sources = blok.background_image.filename ? [
        getFluidGatsbyImage(blok.background_image.filename, {maxWidth: 2560, quality: 60}),
        {
            ...getFluidGatsbyImage(blok.background_image.filename, {maxWidth: 1536, quality: 60}),
            media: `(max-width: 1536px, min-width 1280px)`,
        },
        {
            ...getFluidGatsbyImage(blok.background_image.filename, {maxWidth: 1280, quality: 60}),
            media: `(max-width: 1280px, min-width: 1024px)`,
        },
        {
            ...getFluidGatsbyImage(blok.background_image.filename, {maxWidth: 1024, quality: 60}),
            media: `(max-width: 1024px, min-width: 768px)`,
        },
        {
            ...getFluidGatsbyImage(blok.mobile_background_image?.filename ? blok.mobile_background_image.filename : blok.background_image.filename, {maxWidth: 768, quality: 60}),
            media: `(max-width: 768px)`,
        },
    ] : ''

    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`relative h-screen flex justify-start ${blok.align_desktop === 'top' ? 'md:items-start' : blok.align_desktop === 'bottom' ? 'md:items-end' : 'md:items-center'} ${blok.align_mobile === 'top' ? 'items-start' : blok.align_mobile === 'bottom' ? 'items-end' : 'items-center'} ${blok.image_overlay ? 'bg-gray-300' : ''} ${blok.center_content ? 'text-center' : ''}`}>
                {blok.background_image.filename &&
                    <div className="absolute inset-0">
                        {!blok.background_image.filename?.includes(".mp4") && blok.background_image.filename &&  
                            <Img fluid={sources} placeholderStyle={{ filter: `blur(1rem)` }} className="w-full h-full object-cover" alt={blok.background_image.alt}/>
                        }
                        {blok.background_image.filename?.includes(".mp4") && blok.background_image.filename && 
                            <div className="block w-full h-full object-cover">
                                <video width="1920" height="1080" muted controlsList="nodownload" autoPlay loop playsInline className={`${blok.mobile_background_image.filename?.includes(".mp4") ? 'hidden md:block' : 'block'} w-full h-full object-cover`}>
                                    <source src={blok.background_image.filename} type="video/mp4"/>
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                                {blok.mobile_background_image.filename?.includes(".mp4") && blok.mobile_background_image.filename && 
                                <video width="1920" height="1080" muted controlsList="nodownload" autoPlay loop playsInline className="md:hidden block w-full h-full object-cover ">
                                    <source src={blok.mobile_background_image.filename} type="video/mp4"/>
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                                }
                            </div>
                        }
                        {blok.image_overlay && <div className={`absolute inset-0 mix-blend-multiply bg-gray-300`} aria-hidden="true"></div>}
                    </div>
                }
                <div className={`relative container ${blok.align_desktop === 'top' ? '2xl:mt-48 xl:mt-40' : blok.align_desktop === 'bottom' ? '2xl:mb-32 xl:mb-24' : ''} ${blok.align_mobile === 'top' ? 'lg:mt-32 mt-32' : blok.align_mobile === 'bottom' ? 'lg:mb-12 mb-12' : ''}`}>
                    <Heading className={`rfs:text-8xl max-w-lg ${blok.center_content ? 'text-center mx-auto block' : ''}`} colour={blok.image_overlay ? 'white' : blok.colour}>{blok.title}</Heading>
                    {blok.description &&
                        <p className={`mt-6 md:text-xl max-w-lg ${blok.center_content ? 'text-center mx-auto' : ''} ${blok.image_overlay ? 'text-white' : ''}`}>{blok.description}</p>
                    }
                    {blok.button_link.cached_url && blok.button_text &&
                        <Button to={blok.button_link} className={`btn mt-6`} colour={blok.image_overlay ? 'white' : blok.colour}>{blok.button_text}</Button>
                    }
                </div>
            </div>        
        </SbEditable>
    )
}

export default Hero