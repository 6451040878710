import React from 'react'
import SbEditable from 'storyblok-react'
import RichText from './RichText'
import Button from './Button'
import Heading from './Heading'

const TwoColumnContent = ({ blok }) => {
    return (
        <SbEditable content={blok}>
            <div className="block md:my-24 my-12">
                <div className="container">
                    <Heading className={`rfs:text-6xl mb-6`} colour={blok.colour}>{blok.title}</Heading>
                    <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-x-40 lg:gap-y-0 gap-y-4">
                        <RichText>{blok.content_left}</RichText>
                        <RichText>{blok.content_right}</RichText>
                    </div>
                    {blok.button_link.cached_url && blok.button_text &&
                        <Button to={blok.button_link} className={`btn mt-8`} colour={blok.colour}>{blok.button_text}</Button>
                    }
                </div>
            </div>        
        </SbEditable>
    )
}

export default TwoColumnContent