import React from 'react'
import SbEditable from "storyblok-react"
import RichText from './RichText'
import TestimonialsSliderChars from "/static/images/testimonials_slider_chars.svg"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const FullWidthContent = ({ blok }) => {
    const settings = {
        infinite: true,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 20000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: true,
        dotsClass: "slick-dots yellow",
      };
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`bg-[#FBFBFB] md:pt-24 pt-12 md:pb-24 pb-20 relative md:mt-40 mt-20`}>
                <div className="container text-center">
                    <h2 className="rfs:text-6xl text-giggles-yellow">What our parents say</h2>
                    <Slider {...settings} className="mx-auto max-w-3xl mt-12">
                        {blok.testimonials.map((testimonial, index) => (
                            <div key={index}>
                                <RichText>{testimonial.content}</RichText>
                                <p className="uppercase font-bold tracking-widest mt-6">{testimonial.name}</p>
                            </div>
                        ))}
                    </Slider>
                </div>
                <TestimonialsSliderChars className="md:block hidden absolute -top-16" style={{left: 'calc(50% - 240px)'}}/>
            </div>
        </SbEditable>
    )
}

export default FullWidthContent